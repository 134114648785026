import React from "react";
import { motion } from "framer-motion";
import { ProjectsList } from "./ProjectsList";

function Projects() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className=" h-screen relative flex overflow-hidden flex-col text-left md:flex-row max-w-full justify-evenly mx-auto items-center z-0"
    >
      <h3 className="absolute top-20 md:top-24 uppercase tracking-[20px] text-gray-500 text-xl md:text-2xl">
        Projects
      </h3>

      <div className="relative w-full flex overflow-x-scroll overflow-y-hidden snap-x snap-mandatory z-20 scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-[#F7AB0A]/80">
        {ProjectsList.map((project, i) => {
          return (
            <div
              key={i}
              className="w-screen flex-shrink-0 snap-center flex flex-col space-y-5 items-center justify-center p-10 md:p-44 h-screen"
            >
              <a href={project.liveLink}>
                <motion.img
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.2 }}
                  viewport={{ once: true }}
                  className=" h-28 xl:h-80 md:h-64 object-contain"
                  src={project.image}
                  alt={project.name}
                />
              </a>

              <div className="space-y-5 md:space-y-10 px-0 md:px-10 max-w-6xl">
                <h4 className="text-lg md:text-2xl lg:text-4xl font-semibold text-center">
                  <span className="underline decoration-darkGreen/50">
                    Project {i + 1}:
                  </span>{" "}
                  {project.name}
                </h4>
                <div className="flex items-center space-x-2 justify-center ">
                  {project.technologies.map((technology, i) => {
                    return (
                      <img
                        key={i}
                        className="h-10 w-10 rounded-full object-cover"
                        src={technology.icon}
                        alt="icon"
                      />
                    );
                  })}
                </div>

                <p className="text-sm md:text-md lg:text-lg text-justify ">
                  {project.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-full absolute top-[20%] md:top-[30%] bg-[#F7AB0A]/10 left-0 h-[500px] -skew-y-12"></div>
    </motion.div>
  );
}

export default Projects;
